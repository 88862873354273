import React from "react";
import award from "../../assets/awardbwh.jpg";
import "./award.css";

const Award = () => {
  return (
    <div className="award-group">
      <img src={award} alt="award title" />
      <div className="award-item">
        <h2>NOMINATE YOURSELF OR A STAR LADY </h2>
        <a href="https://forms.gle/7jYNYJfbQCfMsLy17" className="award-button">
          Submit Nomination
        </a>
      </div>
    </div>
  );
};

export default Award;
